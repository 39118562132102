import React, { Component } from 'react';
import './App.scss';
import {Box} from './components/Box/Box';
// import firebase from "./firebase.js";


class App extends Component {
  constructor(){
    super()
    this.state = {
      figure: ''
    }
  }

  btnClick = (val) => {
    this.setState({figure: this.state.figure + val})
  }

  clear = () => this.setState({figure: ''})

  eval = () => {
    const {figure} = this.state;
    if(figure[0] !== '/' && figure[0] !== '*' && figure[0] !== '+' && figure[0] !== '-' && figure[0] !== '.'){
      let x = eval(figure)
      this.setState({figure: x})
    }
    
  }

  render() {
    console.log(this.state.figure[0])
    return (
      <div className="App">
        <Box eval={this.eval} btnClick={this.btnClick} figure={this.state.figure} clear={this.clear} />
      </div>
    );
  }
}

export default App;
