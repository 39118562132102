import React from 'react';
import './Box.scss';

export const Box = props => (
    <>
        <div className='boxp'>
            <div className="screen">
                {props.figure}
            <div className='star'></div>
            </div>
            <div onClick={() => props.btnClick('')} className='btns'></div>
            <div onClick={() => props.btnClick('')} className='btns'>+/-</div>
            <div onClick={() => props.btnClick('')} className='btns'>%</div>
            <div onClick={() => props.btnClick('/')} className='btns'>/</div>
            <div onClick={() => props.btnClick(1)} className='btns'>1</div>
            <div onClick={() => props.btnClick(2)} className='btns'>2</div>
            <div onClick={() => props.btnClick(3)} className='btns'>3</div>
            <div onClick={() => props.btnClick('*')} className='btns'>X</div>
            <div onClick={() => props.btnClick(4)} className='btns'>4</div>
            <div onClick={() => props.btnClick(5)} className='btns'>5</div>
            <div onClick={() => props.btnClick(6)} className='btns'>6</div>
            <div onClick={() => props.btnClick('+')} className='btns'>+</div>
            <div onClick={() => props.btnClick(7)} className='btns'>7</div>
            <div onClick={() => props.btnClick(8)} className='btns'>8</div>
            <div onClick={() => props.btnClick(9)} className='btns'>9</div>
            <div onClick={() => props.btnClick('-')} className='btns'>-</div>
            <div onClick={() => props.btnClick(0)} className='btns'>0</div>
            <div onClick={() => props.btnClick('.')} className='btns'>.</div>
            <div onClick={() => props.clear()} className='btns'>C</div>
            <div onClick={() => props.eval()} className='btns'>=</div>
        </div>
    </>
)
